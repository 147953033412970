import React from "react";
import { Link } from "gatsby";

import Scaffold from "../../../components/portfolio";

export default (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Clarity Coach</h2>

    <h3>Growth</h3>

    <p>If you build it, they might come.</p>

    <p>
      A great app isn't enough, a good marketing strategy is necessary to make a
      strong business. The initial marketing plan for Clarity Coach had three
      components.
    </p>

    <p>
      The first was direct marketing on Reddit. Reddit has subreddits dedicated
      to learning more about and improving at Dota. This is the exact audience
      Clarity Coach is targeting: Technology literate Dota 2 players strongly
      motivated to get better at the game. One such community had frequent
      "Analyse my match" threads. I manually poured over these threads,
      generating free match reviews and sending these privately to the users
      requesting them. At the end of the match review there was a section
      encouraging the user to sign up for more.
    </p>

    <p>
      The second, more scalable approach, is targeted Facebook ads. Facebook ads
      are great as they let you target a very specific audience. For Clarity
      Coach, the basic target audience had a few million users in it, more than
      enough to get started. Testing ad variations is crucial for effectively
      spending money on Facebook, as is testing the landing pages they point to.
      After a bit of testing, setting your maximum spend per click under your
      expected return for a lead can bring in consistent, converting traffic.
    </p>

    <p>
      The third growth technique used at Clarity Coach is social sharing. Users
      gain awards for events they have successfully completed in a match, and
      they can share and compare these on social media sites, which link back to
      dedicated highscore-like tables, where friends of the user can see how
      they compare. This motivates users to sign up for the service to see the
      rest of their stats, which leads to quite a lot of free exposure.
    </p>
  </Scaffold>
);
